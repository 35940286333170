import React, { useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const NotificationSender = ({ selectedUsers, onSuccess }) => {
    const [notification, setNotification] = useState({
        title: '',
        body: '',
        data: { route: '/' }
    });
    const [status, setStatus] = useState({ type: '', message: '' });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setStatus({ type: '', message: '' });

        try {
            const response = await axios.post(`${API_URL}/api/admin/notifications/send`, {
                userIds: selectedUsers,
                notification
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
                }
            });

            setStatus({
                type: 'success',
                message: `Notifications sent successfully! Success: ${response.data.result.success}, Failed: ${response.data.result.failure}`
            });
            
            // Clear form
            setNotification({
                title: '',
                body: '',
                data: { route: '/' }
            });

            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            setStatus({
                type: 'error',
                message: error.response?.data?.error || 'Failed to send notifications'
            });
        } finally {
            setLoading(false);
        }
    };

    if (!selectedUsers || selectedUsers.length === 0) {
        return (
            <div className="rounded-md bg-blue-50 p-4">
                <div className="text-sm text-blue-700">
                    Please select users to send notifications to.
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Notification Title
                    </label>
                    <input
                        id="title"
                        type="text"
                        value={notification.title}
                        onChange={(e) => setNotification({ ...notification, title: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    />
                </div>
                
                <div>
                    <label htmlFor="body" className="block text-sm font-medium text-gray-700">
                        Notification Body
                    </label>
                    <textarea
                        id="body"
                        value={notification.body}
                        onChange={(e) => setNotification({ ...notification, body: e.target.value })}
                        required
                        rows={3}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    />
                </div>
                
                <div>
                    <label htmlFor="route" className="block text-sm font-medium text-gray-700">
                        Deep Link Route (optional)
                    </label>
                    <input
                        id="route"
                        type="text"
                        value={notification.data.route}
                        onChange={(e) => setNotification({
                            ...notification,
                            data: { ...notification.data, route: e.target.value }
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    />
                    <p className="mt-1 text-sm text-gray-500">e.g., /settings, /recipe/123</p>
                </div>
                
                <button
                    type="submit"
                    disabled={loading || !notification.title || !notification.body}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {loading ? 'Sending...' : 'Send Notification'}
                </button>
            </form>
            
            {status.type && (
                <div className={`rounded-md p-4 ${
                    status.type === 'success' 
                        ? 'bg-green-50 text-green-700' 
                        : 'bg-red-50 text-red-700'
                }`}>
                    <p className="text-sm">{status.message}</p>
                </div>
            )}
        </div>
    );
};

export default NotificationSender; 