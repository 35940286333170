import React from 'react';

const PageHeader = ({ title, children }) => {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        <div className="flex items-center space-x-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageHeader; 