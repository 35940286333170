import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  UsersIcon, 
  BookOpenIcon, 
  ClockIcon, 
  CalendarIcon,
  ShoppingCartIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  BeakerIcon,
  ShareIcon,
  PlusIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { 
  fetchShoppingListStats, 
  fetchMealPlannerStats, 
  fetchTopIngredients,
  fetchMealPlannerDayStats
} from '../services/analytics';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [users, setUsers] = useState([]);
  const [shoppingListStats, setShoppingListStats] = useState(null);
  const [mealPlannerStats, setMealPlannerStats] = useState(null);
  const [topIngredients, setTopIngredients] = useState([]);
  const [mealPlannerDayStats, setMealPlannerDayStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          statsRes, 
          recipesRes, 
          usersRes,
          shoppingListStatsRes,
          mealPlannerStatsRes,
          topIngredientsRes,
          mealPlannerDayStatsRes
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/admin/stats`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/admin/recipes`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
          }),
          fetchShoppingListStats(),
          fetchMealPlannerStats(),
          fetchTopIngredients(),
          fetchMealPlannerDayStats()
        ]);

        setStats(statsRes.data);
        setRecipes(recipesRes.data);
        setUsers(usersRes.data);
        setShoppingListStats(shoppingListStatsRes);
        setMealPlannerStats(mealPlannerStatsRes);
        setTopIngredients(topIngredientsRes);
        setMealPlannerDayStats(mealPlannerDayStatsRes);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate user registration trend (last 7 days)
  const userRegistrationData = {
    labels: Array.from({ length: 7 }, (_, i) => {
      const d = new Date();
      d.setDate(d.getDate() - (6 - i));
      return d.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }),
    datasets: [{
      label: 'New Users',
      data: Array.from({ length: 7 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (6 - i));
        date.setHours(0, 0, 0, 0);
        return users.filter(user => {
          const userDate = new Date(user.createdAt);
          return userDate >= date && userDate < new Date(date.getTime() + 86400000);
        }).length;
      }),
      borderColor: 'rgb(59, 130, 246)',
      backgroundColor: 'rgba(59, 130, 246, 0.5)',
      tension: 0.4,
    }]
  };

  // Calculate recipe categories distribution
  const recipeCategories = recipes.reduce((acc, recipe) => {
    if (recipe.category) {
      acc[recipe.category] = (acc[recipe.category] || 0) + 1;
    }
    return acc;
  }, {});

  const recipeCategoryData = {
    labels: Object.keys(recipeCategories),
    datasets: [{
      label: 'Recipes by Category',
      data: Object.values(recipeCategories),
      backgroundColor: [
        'rgba(59, 130, 246, 0.5)',
        'rgba(16, 185, 129, 0.5)',
        'rgba(245, 158, 11, 0.5)',
        'rgba(239, 68, 68, 0.5)',
        'rgba(139, 92, 246, 0.5)',
      ],
      borderWidth: 1,
    }]
  };

  const mealPlannerDayData = {
    labels: mealPlannerDayStats?.dayStats.map(stat => stat.day) || [],
    datasets: [{
      label: 'Meals Planned',
      data: mealPlannerDayStats?.dayStats.map(stat => stat.count) || [],
      backgroundColor: 'rgba(59, 130, 246, 0.5)',
      borderColor: 'rgb(59, 130, 246)',
      borderWidth: 1,
    }]
  };

  const topIngredientsData = {
    labels: topIngredients.map(ing => ing.name),
    datasets: [{
      label: 'Usage Count',
      data: topIngredients.map(ing => ing.count),
      backgroundColor: [
        'rgba(59, 130, 246, 0.5)',
        'rgba(16, 185, 129, 0.5)',
        'rgba(245, 158, 11, 0.5)',
        'rgba(239, 68, 68, 0.5)',
        'rgba(139, 92, 246, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(153, 102, 255, 0.5)',
      ],
      borderWidth: 1,
    }]
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="h-8 bg-gray-200 rounded w-1/2 mb-4"></div>
                <div className="h-6 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  const stats_cards = [
    {
      name: 'Total Users',
      value: stats?.totalUsers || 0,
      icon: UsersIcon,
      change: '+20%',
      changeType: 'increase',
    },
    {
      name: 'Total Recipes',
      value: stats?.totalRecipes || 0,
      icon: BookOpenIcon,
      change: '+15%',
      changeType: 'increase',
    },
    {
      name: 'Active Users (Last 7 Days)',
      value: users.filter(user => {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        return new Date(user.createdAt) >= lastWeek;
      }).length,
      icon: ClockIcon,
    },
    {
      name: 'Recipes This Month',
      value: recipes.filter(recipe => {
        const thisMonth = new Date();
        thisMonth.setDate(1);
        return new Date(recipe.created_at) >= thisMonth;
      }).length,
      icon: CalendarIcon,
    },
  ];

  const shopping_list_stats = [
    {
      name: 'Total Shopping Lists',
      value: shoppingListStats?.totalLists || 0,
      icon: ListBulletIcon,
    },
    {
      name: 'Users with Lists',
      value: shoppingListStats?.usersWithLists || 0,
      icon: UsersIcon,
    },
    {
      name: 'Shared Lists',
      value: shoppingListStats?.sharedLists || 0,
      icon: ShareIcon,
    },
    {
      name: 'Lists per User',
      value: shoppingListStats?.listsPerUser || 0,
      icon: UserIcon,
    },
  ];

  const shopping_list_averages = [
    {
      name: 'Avg. Ingredients per List',
      value: shoppingListStats?.avgIngredientsPerList || 0,
      icon: BeakerIcon,
    },
    {
      name: 'Avg. Extra Ingredients',
      value: shoppingListStats?.avgExtraIngredientsPerList || 0,
      icon: PlusIcon,
    },
    {
      name: 'Users Using Planner',
      value: mealPlannerStats?.usersUsingPlanner || 0,
      icon: UsersIcon,
    },
    {
      name: 'Days with Meals',
      value: mealPlannerStats?.daysWithMeals || 0,
      icon: CalendarDaysIcon,
    },
  ];

  return (
    <div>
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>

      {/* General Stats */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">General Statistics</h2>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {stats_cards.map((item) => (
            <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">{item.value}</div>
                        {item.change && (
                          <div className={`ml-2 flex items-baseline text-sm font-semibold ${
                            item.changeType === 'increase' ? 'text-green-600' : 'text-red-600'
                          }`}>
                            {item.change}
                          </div>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Shopping List Stats */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Shopping List Statistics</h2>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {shopping_list_stats.map((item) => (
            <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">{item.value}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Shopping List Averages & Meal Planning */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Shopping List Averages & Meal Planning</h2>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {shopping_list_averages.map((item) => (
            <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">{item.value}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Charts */}
      <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
        {/* User Registration Trend */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">User Registration Trend</h3>
          <Line
            data={userRegistrationData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1
                  }
                }
              },
              plugins: {
                legend: {
                  position: 'top',
                }
              }
            }}
          />
        </div>

        {/* Recipe Categories */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Recipe Categories</h3>
          <Bar
            data={recipeCategoryData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                }
              }
            }}
          />
        </div>

        {/* Most Planned Days */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Most Planned Days</h3>
          <Bar
            data={mealPlannerDayData}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    stepSize: 1
                  }
                }
              },
              plugins: {
                legend: {
                  position: 'top',
                }
              }
            }}
          />
        </div>

        {/* Top 10 Ingredients */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Top 10 Ingredients</h3>
          <Doughnut
            data={topIngredientsData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'right',
                }
              }
            }}
          />
        </div>
      </div>

      {/* Additional Insights */}
      <div className="mt-8">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Key Insights</h3>
          <div className="space-y-4">
            <div>
              <h4 className="text-sm font-medium text-gray-500">User Engagement</h4>
              <p className="mt-1 text-sm text-gray-900">
                {Math.round((users.filter(u => u.isActive).length / users.length) * 100)}% of users are currently active.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-500">Recipe Distribution</h4>
              <p className="mt-1 text-sm text-gray-900">
                Average of {(recipes.length / users.length).toFixed(1)} recipes per user.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-500">Vegan Recipes</h4>
              <p className="mt-1 text-sm text-gray-900">
                {Math.round((recipes.filter(r => r.is_vegan).length / recipes.length) * 100)}% of recipes are vegan-friendly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 