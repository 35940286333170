import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeaders = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
});

export const fetchShoppingListStats = async () => {
  const response = await axios.get(`${API_URL}/api/admin/analytics/shopping-lists`, getAuthHeaders());
  return response.data;
};

export const fetchMealPlannerStats = async () => {
  const response = await axios.get(`${API_URL}/api/admin/analytics/meal-planner`, getAuthHeaders());
  return response.data;
};

export const fetchTopIngredients = async () => {
  const response = await axios.get(`${API_URL}/api/admin/analytics/top-ingredients`, getAuthHeaders());
  return response.data;
};

export const fetchMealPlannerDayStats = async () => {
  const response = await axios.get(`${API_URL}/api/admin/analytics/meal-planner-days`, getAuthHeaders());
  return response.data;
}; 