import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        setLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/verify`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.token) {
        localStorage.setItem('adminToken', response.data.token);
      }

      setIsAuthenticated(true);
      setUser(response.data.user);
    } catch (error) {
      console.error('Auth check error:', error);
      localStorage.removeItem('adminToken');
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const refreshUser = async () => {
    return checkAuth();
  };

  const login = async (email, password, totpToken = null) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, {
        email,
        password,
        totpToken
      });

      if (response.data.token && response.data.user) {
        localStorage.setItem('adminToken', response.data.token);
        setIsAuthenticated(true);
        setUser(response.data.user);
        return response.data;
      }
      return false;
    } catch (error) {
      if (error.response?.data?.requires2FA) {
        return { requires2FA: true };
      }
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('adminToken');
    setIsAuthenticated(false);
    setUser(null);
  };

  // 2FA Management
  const setup2FA = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/2fa/setup`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const verify2FA = async (setupToken, verificationCode) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/2fa/verify`,
        { setupToken, token: verificationCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Update user state to reflect 2FA status
      setUser(prev => ({ ...prev, has2FA: true }));
      
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const disable2FA = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/2fa/disable`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Update user state to reflect 2FA status
      setUser(prev => ({ ...prev, has2FA: false }));
      
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const generateNewBackupCodes = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/2fa/backup-codes`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        user, 
        login, 
        logout,
        setup2FA,
        verify2FA,
        disable2FA,
        generateNewBackupCodes,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 