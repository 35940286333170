import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import PageHeader from '../components/PageHeader';

const Settings = () => {
  const { user, setup2FA, verify2FA, disable2FA, generateNewBackupCodes, refreshUser } = useAuth();
  const [setupData, setSetupData] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [backupCodes, setBackupCodes] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshUser();
  }, []);

  const handleSetup2FA = async () => {
    try {
      setLoading(true);
      setError('');
      const data = await setup2FA();
      setSetupData(data);
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to setup 2FA');
    } finally {
      setLoading(false);
    }
  };

  const handleVerify2FA = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      const result = await verify2FA(setupData.setupToken, verificationCode);
      setBackupCodes(result.backupCodes);
      setSuccess('2FA has been enabled successfully');
      setSetupData(null);
      setVerificationCode('');
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to verify 2FA');
    } finally {
      setLoading(false);
    }
  };

  const handleDisable2FA = async () => {
    if (!window.confirm('Are you sure you want to disable 2FA? This will make your account less secure.')) {
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      await disable2FA();
      await refreshUser();
      setSuccess('2FA has been disabled successfully');
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to disable 2FA');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewBackupCodes = async () => {
    if (!window.confirm('Generating new backup codes will invalidate your old ones. Are you sure?')) {
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      const result = await generateNewBackupCodes();
      setBackupCodes(result.backupCodes);
      setSuccess('New backup codes have been generated');
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to generate new backup codes');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <PageHeader 
        title="Security Settings"
      />
      <div className="max-w-3xl mx-auto">
        {error && (
          <div className="mb-4 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>
              </div>
            </div>
          </div>
        )}

        {success && (
          <div className="mb-4 rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">{success}</h3>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Two-Factor Authentication (2FA)
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in.
              </p>
            </div>
            <div className="mt-5">
              {user && !user.has2FA && !setupData && (
                <button
                  type="button"
                  onClick={handleSetup2FA}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                >
                  {loading ? 'Setting up...' : 'Setup 2FA'}
                </button>
              )}

              {setupData && (
                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-gray-700 mb-2">
                      1. Scan this QR code with your authenticator app:
                    </p>
                    <img
                      src={setupData.qrCode}
                      alt="2FA QR Code"
                      className="w-48 h-48"
                    />
                  </div>

                  <form onSubmit={handleVerify2FA} className="space-y-4">
                    <div>
                      <p className="text-sm text-gray-700 mb-2">
                        2. Enter the verification code from your authenticator app:
                      </p>
                      <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        placeholder="Enter 6-digit code"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        required
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength={6}
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={loading || verificationCode.length !== 6}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                    >
                      {loading ? 'Verifying...' : 'Verify and Enable 2FA'}
                    </button>
                  </form>
                </div>
              )}

              {(() => {
                if (user && user.has2FA) {
                  return (
                    <div className="space-y-4">
                      <p className="text-sm text-gray-700">
                        ✅ Two-factor authentication is enabled
                      </p>
                      
                      <div className="space-x-4">
                        <button
                          type="button"
                          onClick={handleGenerateNewBackupCodes}
                          disabled={loading}
                          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                        >
                          {loading ? 'Generating...' : 'Generate New Backup Codes'}
                        </button>

                        <button
                          type="button"
                          onClick={handleDisable2FA}
                          disabled={loading}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                        >
                          {loading ? 'Disabling...' : 'Disable 2FA'}
                        </button>
                      </div>
                    </div>
                  );
                }
                return null;
              })()}

              {backupCodes.length > 0 && (
                <div className="mt-6">
                  <h4 className="text-md font-medium text-gray-900 mb-2">
                    Backup Codes
                  </h4>
                  <p className="text-sm text-gray-500 mb-4">
                    Save these backup codes in a secure place. Each code can only be used once to sign in if you lose access to your authenticator app.
                  </p>
                  <div className="bg-gray-50 p-4 rounded-md">
                    <div className="grid grid-cols-2 gap-4">
                      {backupCodes.map((code, index) => (
                        <div key={index} className="font-mono text-sm">
                          {code}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings; 