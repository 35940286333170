import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { EyeIcon } from '@heroicons/react/24/outline';

const Recipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewingRecipe, setViewingRecipe] = useState(null);

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/recipes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setRecipes(response.data);
    } catch (err) {
      setError('Failed to load recipes');
      console.error('Error fetching recipes:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatRecipeForDisplay = (recipe) => {
    const {
      id,
      title,
      summary,
      image_url,
      youtube_url,
      source_url,
      prep_time,
      cook_time,
      total_time,
      servings,
      calories,
      category,
      author,
      is_vegan,
      ingredients = [],
      steps = []
    } = recipe;

    return {
      id,
      title,
      summary,
      image_url,
      youtube_url,
      source_url,
      prep_time,
      cook_time,
      total_time,
      servings,
      calories,
      category,
      author,
      is_vegan,
      ingredients: ingredients.map(ing => ({
        name: ing.name,
        quantity: ing.quantity,
        unit: ing.unit
      })),
      instructions: steps.sort((a, b) => a.step_number - b.step_number)
        .map(step => step.instruction)
    };
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="bg-white p-4 rounded-lg shadow">
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="space-y-3 mt-4">
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Recipes</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all recipes in the platform.
          </p>
        </div>
      </div>
      
      <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {recipes.map((recipe) => (
          <div key={recipe.id} className="bg-white overflow-hidden shadow rounded-lg">
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={recipe.image_url || '/placeholder-recipe.jpg'}
                alt={recipe.title}
                className="object-cover w-full h-48"
                onError={(e) => {
                  e.target.src = '/placeholder-recipe.jpg';
                }}
              />
            </div>
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-900">{recipe.title}</h3>
              <div className="mt-2 flex justify-end">
                <button
                  onClick={() => setViewingRecipe(recipe)}
                  className="text-primary-600 hover:text-primary-900"
                >
                  <EyeIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* View Modal */}
      {viewingRecipe && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-3xl w-full p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">{viewingRecipe.title}</h3>
              <button
                onClick={() => setViewingRecipe(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              <img
                src={viewingRecipe.image_url || '/placeholder-recipe.jpg'}
                alt={viewingRecipe.title}
                className="w-full h-64 object-cover rounded-lg"
                onError={(e) => {
                  e.target.src = '/placeholder-recipe.jpg';
                }}
              />
              <div className="mt-4">
                <pre className="bg-gray-50 rounded p-4 overflow-x-auto">
                  <code>{JSON.stringify(formatRecipeForDisplay(viewingRecipe), null, 2)}</code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recipes; 